.App {
   text-align: center;
 }
 
 .App-logo {
   height: 40vmin;
   pointer-events: none;
 }
 
 @media (prefers-reduced-motion: no-preference) {
   .App-logo {
     animation: App-logo-spin infinite 20s linear;
   }
 }
 
 .App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
 }
 
 .App-link {
   color: #61dafb;
 }
 
 @keyframes App-logo-spin {
   from {
     transform: rotate(0deg);
   }
   to {
     transform: rotate(360deg);
   }
 }
 
 .scheduler-title-container{
   width: 80%;
   display: inline-block;
   text-align: center;
   font-weight: 600;
 }
 
 .treeview-title-container {
   display: inline-block;
  /*  width: 20%; */
   padding-left: 20px;
   text-align: left;
   font-weight: 600;
 }
 
 .scheduler-component {
   float: left;
   width: 80%;
   /* max-height: max-content; */
   height: 100%;
 }
 
 .treeview-component {
   float: right;
   width: 20%;
   color: #fff;
 }
 
 .content-wrapper {
   display: -ms-flexbox;
   display: flex;
 }
 
 .e-device-hover {
   background-color: #e0e0e0 !important;
 }
 
 .schedule-container {
   padding-right: 10px;
   width: 100%;
 }
 
 .title-container {
   padding-bottom: 10px;
 }
 
 .treeview-external-drag #waiting {
   height: 100%;
   padding: 0;
 }
 
 .treeview-external-drag #waitdetails {
   width: 95%;
   float: left;
   height: 100%;
   padding: 0;
 }
 
 .treeview-external-drag #waitlist {
   width: 100%;
   height: 50%;
   font-weight: bold;
   font-family: "Segoe UI";
   font-size: 12px;
   padding: 5px 0 0 10px;
   overflow: hidden;
   text-overflow: ellipsis;
 }
 
 .treeview-external-drag #waitcategory {
   height: 50%;
   font-family: "Segoe UI";
   font-size: 10px;
   opacity: 0.6;
   padding-left: 10px;
   padding-top: 5px;
   overflow: hidden;
   text-overflow: ellipsis;
 }
 
 .treeview-external-drag .e-list-text,
 .e-bigger .treeview-external-drag .e-list-text {
   border: 0.5px solid #E1E7EC;
   height: 50px;
   line-height: 15px;
   padding: 0 5px;
   width: 220px;
   color: #fff;
 }
 
 .treeview-external-drag .e-list-parent,
 .e-bigger .treeview-external-drag .e-list-parent {
   height: 100%;
   padding: 0 2px;
 }
 
 .treeview-external-drag .e-list-item,
 .e-bigger .treeview-external-drag .e-list-item {
   height: 100%;
   padding: 0 0 5px 0;
   color: #ffffff ;
 }
 
 .treeview-external-drag .e-fullrow,
 .e-bigger .treeview-external-drag .e-fullrow {
   height: 55px;
 }
 
 .treeview-external-drag .e-list-item.e-hover>.e-fullrow,
 .treeview-external-drag .e-list-item.e-active>.e-fullrow,
 .treeview-external-drag .e-list-item.e-active.e-hover>.e-fullrow,
 .e-bigger .treeview-external-drag .e-list-item.e-hover>.e-fullrow,
 .e-bigger .treeview-external-drag .e-list-item.e-active>.e-fullrow,
 .e-bigger .treeview-external-drag .e-list-item.e-active.e-hover>.e-fullrow {
   background-color: transparent;
   border-color: transparent;
   box-shadow: none !important;
 }
 
 .treeview-external-drag .e-text-content,
 .e-bigger .treeview-external-drag .e-text-content {
   padding: 0;
   background-color: inherit;
 }
 
 .e-drag-item.e-treeview.treeview-external-drag,
 .e-bigger .e-drag-item.e-treeview.treeview-external-drag {
   padding: 0 !important;
 }
 
 .e-schedule.schedule-drag-drop .e-timeline-view .e-resource-left-td,
 .e-schedule.schedule-drag-drop .e-timeline-month-view .e-resource-left-td {
   width: 160px;
 }
 
 .e-schedule.schedule-drag-drop .e-resource-cells.e-parent-node .specialist-category {
   padding-left: 30px
 }
 
 .e-schedule.e-rtl.schedule-drag-drop .e-resource-cells.e-parent-node .specialist-category {
   padding-right: 30px
 }
 
 .e-schedule.schedule-drag-drop .e-resource-cells.e-child-node .specialist-category,
 .e-schedule.schedule-drag-drop .e-resource-cells.e-child-node .specialist-name {
   padding: 5px
 }
 
 .e-schedule.schedule-drag-drop .e-resource-cells.e-parent-node .specialist-name {
   padding: 0 10px
 }
 
 .e-schedule.schedule-drag-drop .e-resource-cells.e-parent-node .template-wrap {
   padding: 3px 0px;
 }
 
 .title-text {
   font-size: 18px;
   margin: 0px;
   font-weight: bold;
   text-align: center;
 }
 
 .e-schedule.schedule-drag-drop .specialist-image {
   width: 45px;
   height: 40px;
   float: left;
   border-radius: 50%;
   margin-right: 10px;
 }
 
 .e-schedule.schedule-drag-drop .specialist-name {
   font-size: 13px;
 }
 
 .e-schedule.schedule-drag-drop .specialist-designation {
   font-size: 10px;
 }
 
 .e-schedule-dialog .e-all-day-time-zone-row,
 .e-schedule-dialog .e-location-container,
 .e-bigger .e-schedule-dialog .e-all-day-time-zone-row,
 .e-bigger .e-schedule-dialog .e-location-container {
   display: none;
 }
 
 .e-schedule-dialog .e-subject-container,
 .e-bigger .e-schedule-dialog .e-subject-container {
   padding-right: 0;
   width: 100%;
 }
 
 .e-schedule-dialog.e-rtl .e-subject-container,
 .e-bigger .e-schedule-dialog.e-rtl .e-subject-container {
   padding-left: 0;
 }
 
 @media (max-width: 550px) {
   .content-wrapper {
       display: block;
   }
 
   .schedule-container {
       padding-bottom: 10px
   }
 
   .treeview-external-drag.e-treeview,
   .e-bigger .treeview-external-drag.e-treeview {
       width: 250px;
   }
 
   .e-bigger .treeview-external-drag.e-treeview.e-drag-item {
       position: relative !important;
   }
 }
 .e-disble-not-allowed {
   cursor: unset !important;
 }
 .e-drag-item.treeview-external-drag .e-icon-expandable {
   display: none;
 }


/*  =========================== */

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
     sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }
 
 code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
     monospace;
 }


/*  ================ */
.e-list-text {
   color: #fff !important;
}
 