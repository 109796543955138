@tailwind base;
@tailwind components;
@tailwind utilities;


@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #7a7f9d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #21295c;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #21295c;
}

.Toastify__toast-container {
  z-index: 9999;
}

@layer base {
  section {
    @apply py-[75px];
  }
}

@layer components {
  /* .btn {
    @apply bg-buttonBgColor py-[15px] px-[35px] rounded-[50px] text-white font-[600] mt-[38px];
  } */
  .btn {
    @apply bg-buttonBgColor py-[15px] px-[35px] rounded-[50px] text-white font-[600] mt-[38px];
  }

  .btn_clicked {
    @apply bg-white text-buttonBgColor border-2 border-blue-500;
  }

  .container {
    @apply max-w-full w-[1440px] px-[20px] mx-auto;
  }

  .header {
    /* @apply bg-[url('./assets/images/mask.png')] bg-no-repeat bg-cover bg-center w-full h-[100px] leading-[100px]; */
    @apply  bg-cover bg-center w-full h-[80px] leading-[100px];

  }

  .hero__section {
    /* @apply bg-[url('./assets/images/hero-bg.png')] bg-no-repeat bg-cover bg-center; */
    @apply  bg-cover bg-center;
  }

  .text__para {
    @apply text-[18px] leading-[30px] text-textColor font-[400] mt-[18px];
  }

  .heading {
    @apply text-[44px] leading-[54px] font-[700] text-headingColor;
  }

  .form__label {
    @apply text-gray-400 text-[12px] leading-7 font-semibold mb-2;
  }

  .form__input {
    @apply w-full px-4 py-3 border-2 border-solid border-[#0066ff61] focus:outline-none focus:border-[#0067FF] text-[16px] leading-7 text-headingColor placeholder:text-textColor rounded-md cursor-pointer;
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    @apply w-full;
  }
  section {
    padding: 35px 0;
  }
}

@media only screen and (max-width: 768px) {
  .heading {
    @apply text-[26px] leading-[36px];
  }

  .text__para {
    @apply text-[16px] leading-[28px] mt-3;
  }
}

@layer utilities {
  .grid-cols-custom {
    grid-template-columns: 4fr 1fr 3fr;
  }
}

/* ===================== */



